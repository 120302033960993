import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import SocialSharing from '@/Space/components/SocialSharing.vue';
import { useAddFavoriteSpaceMutation, useRemoveFavoriteSpaceMutation, UserRoleState } from '@/generated-types/graphql.types';
import { optimizeImage } from '@/util/globalHelpers';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { getItemLocalStorage, saveCurrentPath } from '@/util/utils';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
export default defineComponent({
    components: {
        SocialSharing
    },
    props: {
        isSpace: {
            type: Boolean,
            default: false
        },
        images: {
            type: Array,
            default: () => []
        },
        space: {
            type: Object,
            default: () => null
        },
        venue: {
            type: Object,
            default: () => null
        },
        showSharingButton: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { root } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const isSpaceFavorite = ref(props.space?.is_favorite || false);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const { mutate: addFavoriteSpace } = useAddFavoriteSpaceMutation({});
        const { mutate: removeFavoriteSpace } = useRemoveFavoriteSpaceMutation({});
        const handleClickOnFavorite = () => {
            if (performAccountFlowAction.value && !user.value) {
                preSaveAction({
                    type: PRE_SAVE_ACTION_TYPES.SPACE_ID,
                    spaceId: props.space.space_id
                });
                saveCurrentPath(root.$route.fullPath.slice(1));
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            makeAction();
        };
        const makeAction = (preSaved) => {
            if (preSaved && props.space?.is_favorite)
                return;
            const spaceId = props.space.space_id;
            (isSpaceFavorite.value
                ? removeFavoriteSpace({ spaceId })
                : addFavoriteSpace({ spaceId }))
                .then(() => {
                isSpaceFavorite.value = !isSpaceFavorite.value;
            })
                .catch(() => {
                const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
                addToast({
                    text: root.$i18n.t('favorites.error'),
                    type: 'danger',
                    dismissAfter: 5000
                });
            });
        };
        onMounted(() => {
            const preSavedType = localStorage.getItem('preSavedType');
            let preSavedSpaceId = getItemLocalStorage('preSavedSpaceId');
            if (props.space &&
                preSavedSpaceId === props.space.space_id &&
                preSavedType &&
                preSavedType === PRE_SAVE_ACTION_TYPES.SPACE_ID) {
                makeAction(true);
                resetPreSaveAction();
            }
        });
        return {
            isSpaceFavorite,
            showSharingModal: ref(false),
            handleClickOnFavorite,
            makeAction,
            optimizeImage
        };
    }
});
