import { defineComponent, computed } from '@vue/composition-api';
import ModalPopup from '@/shared/components/ModalPopup.vue';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { SpaceRoutes } from '@/Space/space.const';
import { VenueRoutes } from '@/Venue/venue.const';
export default defineComponent({
    components: {
        ModalPopup
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root } = context;
        const options = [
            {
                name: 'facebook',
                social: true,
                iconName: 'fa-brands fa-facebook fa-lg pre-icon'
            },
            {
                name: 'twitter',
                social: true,
                iconName: 'fa-brands fa-x-twitter fa-lg pre-icon'
            },
            {
                name: 'linkedin',
                social: true,
                iconName: 'fa-brands fa-linkedin fa-lg pre-icon'
            },
            {
                name: 'copy',
                social: false,
                iconName: 'fa-solid fa-copy fa-lg pre-icon'
            },
            {
                name: 'email',
                social: true,
                iconName: 'fa-solid fa-envelope fa-lg pre-icon'
            }
        ];
        const sharedData = computed(() => {
            if (!props.data)
                return {};
            let model;
            let modelName;
            let modelDescription;
            if (props.data.venue_id || props.data.venueId) {
                model = 'venue';
                modelName = props.data.venue_name || props.data.venueName;
                modelDescription =
                    props.data.venue_description || props.data.description;
            }
            else {
                model = 'space';
                modelName = props.data.internal_name
                    ? `${props.data.venue.venue_name} - ${props.data.internal_name}`
                    : props.data.space_name || props.data.name;
                modelDescription = `${root.$i18n.t('filter.label_price_from', {
                    price: props.data.half_day_price
                })} ${props.data.space_booking_type === 'DIRECT'
                    ? root.$i18n.t('filter.label_direct')
                    : ''}`;
            }
            const route = model === 'venue' ? VenueRoutes.VENUE : SpaceRoutes.SPACE;
            const spaceUrl = props.data.space_url || props.data.spaceUrl;
            const venueUrl = props.data.venue_url || props.data.venueUrl;
            const url = model === 'venue' ? venueUrl : spaceUrl;
            return {
                name: modelName,
                description: modelDescription,
                link: `${window.location.origin}/${route}${url}`
            };
        });
        const copyLink = () => {
            // Adding toaster and copying to clipboard
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            root.$copyText(sharedData.value.link);
            addToast({
                text: root.$i18n.t('common.social_sharing.link_copied'),
                type: 'success',
                dismissAfter: 4000
            });
        };
        const onSocialShareButtonClick = (socialNetworkName) => {
            if (!socialNetworkName || !props.data)
                return;
            // Track event
            globalThis.$gtm.trackEvent({
                event: `${props.data.venue_id ? 'venue' : 'space'}_social_share`,
                category: 'social',
                action: `${props.data.venue_id ? 'venue' : 'space'}_shared_` +
                    socialNetworkName,
                label: `${props.data.venue_id ? 'venue' : 'space'}_social_share`,
                value: socialNetworkName,
                noninteraction: false
            });
        };
        return {
            options,
            sharedData,
            copyLink,
            onSocialShareButtonClick
        };
    }
});
