var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section section-hero"},[_c('div',{staticClass:"venue-photos-row"},[_c('div',{staticClass:"venue-photos-col venue-photos-col-1"},[(_vm.$mq && !_vm.$mq.desktop)?_c('div',{staticClass:"space-carousel",style:({ height: _vm.$mq.phone ? '300px' : '400px' })},[_c('vueper-slides',{staticClass:"no-shadow",attrs:{"visible-slides":1,"bullets":false,"gap":3,"slide-ratio":1 / 4,"dragging-distance":200}},_vm._l((_vm.images),function(slide,i){return _c('vueper-slide',{key:i,attrs:{"image":_vm.optimizeImage(
                ((slide.image_url) + "/tr:w-" + (_vm.$mq.mini ? 400 : _vm.$mq.tablet ? 800 : 600))
              )}})}),1)],1):_c('div',{staticClass:"venue-photo venue-photo-large"},[_c('div',{staticClass:"venue-photo-image",style:({
            backgroundImage: _vm.images.length
              ? ("url(" + (_vm.optimizeImage(
                  ((_vm.images[0].image_url) + "?tr=w-859,h-644")
                )) + ")")
              : 'url(/img/common/placeholder-image.png)'
          }),on:{"click":function($event){_vm.images.length ? _vm.$emit('show-images') : false}}})]),(_vm.showSharingButton)?_c('WMButton',{staticClass:"section-hero__btn-button",style:({ top: _vm.isSpace ? '-120px' : '-90px' }),attrs:{"data-gtm":"social_share_button","small":"","color":"accent","disabled":(_vm.venue && !_vm.venue.is_active) || (_vm.space && !_vm.space.is_active),"icon-class-name":("fa-solid fa-share fa-" + (_vm.$mq && _vm.$mq.phone ? 'ml' : 'xs'))},on:{"click":function($event){_vm.showSharingModal = true}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.share')))])]):_vm._e(),(_vm.isSpace)?_c('WMButton',{class:['section-hero__btn-button', { selected: _vm.isSpaceFavorite }],style:({ top: '-120px' }),attrs:{"data-gtm":"favorites_button","small":"","color":"accent","disabled":_vm.space && !_vm.space.is_active,"icon-class-name":("fa-sharp fa-" + (_vm.isSpaceFavorite ? 'solid' : 'regular') + " fa-heart fa-" + (_vm.$mq && _vm.$mq.phone ? 'ml' : 'xs'))},on:{"click":_vm.handleClickOnFavorite}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.isSpaceFavorite ? _vm.$t('favorites.buttons.remove_from_favorites') : _vm.$t('favorites.buttons.add_to_favorites')))])]):_vm._e()],1),(_vm.images.length > 1)?_c('div',{staticClass:"venue-photos-col venue-photos-col-2"},[(_vm.images[1])?_c('div',{staticClass:"venue-photo venue-photo-small"},[_c('div',{staticClass:"venue-photo-image",style:({
            backgroundImage: _vm.images[1]
              ? ("url(" + (_vm.optimizeImage(
                  ((_vm.images[1].image_url) + "?tr=w-422,h-240")
                )) + ")")
              : 'url(/img/common/placeholder-image.png)'
          }),on:{"click":function($event){_vm.images.length ? _vm.$emit('show-images', 1) : false}}})]):_vm._e(),(_vm.images[2])?_c('div',{staticClass:"venue-photo venue-photo-small"},[_c('div',{staticClass:"venue-photo-image",style:({
            backgroundImage: _vm.images[2]
              ? ("url(" + (_vm.optimizeImage(
                  ((_vm.images[2].image_url) + "?tr=w-422,h-240")
                )) + ")")
              : 'url(/img/common/placeholder-image.png)'
          }),on:{"click":function($event){_vm.images.length ? _vm.$emit('show-images', 2) : false}}})]):_vm._e()]):_vm._e()]),(_vm.showSharingModal && _vm.showSharingButton)?_c('SocialSharing',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.showSharingModal),expression:"showSharingModal"}],attrs:{"data":_vm.space || _vm.venue},on:{"close":function($event){_vm.showSharingModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }