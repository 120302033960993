import { defineComponent, ref } from '@vue/composition-api';
import { optimizeImage } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        images: {
            type: Array,
            default: () => []
        },
        selectedImageIndex: {
            type: Number,
            default: 0
        }
    },
    setup(props, context) {
        const { emit } = context;
        const selectedImage = ref(props.selectedImageIndex || 0);
        const wrapper = ref(null);
        const onPrev = () => {
            if (props.images && props.images.length > 0) {
                selectedImage.value =
                    (selectedImage.value - 1 + props.images.length) % props.images.length;
            }
        };
        const onNext = (e) => {
            if (props.images && props.images.length > 0) {
                selectedImage.value = (selectedImage.value + 1) % props.images.length;
            }
        };
        const onClickThumbnail = (index) => {
            selectedImage.value = index;
        };
        const onEscape = () => {
            emit('close');
        };
        return {
            selectedImage,
            wrapper,
            onClickThumbnail,
            onEscape,
            onNext,
            onPrev,
            optimizeImage
        };
    }
});
